import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Disclosure from "../../../components/blog/legal/affiliateMarketing.mdx";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
export const query = graphql`
  {
    mdx(slug: { eq: "what-is-the-quickest-way-to-lose-belly-fat/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_first_desc
        image_first_credit_text
        image_first_credit_link
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_second_desc
        image_second_credit_text
        image_second_credit_link
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_third_desc
        image_third_credit_text
        image_third_credit_link
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        image_fourth_desc
        image_fourth_credit_text
        image_fourth_credit_link
        image_faq {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_faq_alt
        image_faq_desc
        image_faq_credit_text
        image_faq_credit_link
        image_app_promotion {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_app_promotion_alt
        image_app_promotion_desc
        image_app_promotion_credit_text
        image_app_promotion_credit_link
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const NavLinkGlobal = makeShortcode("NavLinkGlobal");
const AffiliateLink = makeShortcode("AffiliateLink");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Learn all about how Philipp
  managed to lose <b>47 pounds</b> in only <b>38 weeks</b> and gained muscle at
  the same time. The recipe for success is 3 to 4 short workouts a week and a
  balanced and healthy diet. Read all about his strategy in this article and
  join Philipp on his journey to reaching his dreambody!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} imageDesc={props.data.mdx.frontmatter.image_first_desc} imageAuthor={props.data.mdx.frontmatter.image_first_credit_text} imageLink={props.data.mdx.frontmatter.image_first_credit_link} mdxType="Image" />
    <h2>{`Why did Philipp decide to take this step?`}</h2>
    <p>
  Before explaining in detail what Philipp did to achieve his dreambody, let me
  first tell you about the beginnings of his journey. It's very important for
  you to understand what the motivation and inner drive behind this incredible
  achievement were. I would also like to tell you how the contact came about and
  how the collaboration went. Also you will understand why it is very important
  to track your calories and macronutrients as well as your body weight.
    </p>
    <h3>{`Philipp's Motivation`}</h3>
    <p>
  What distinguishes a successful person from an unsuccessful one? Why does one
  person achieve his goals, while another fails again and again? How is it
  possible that Philipp lost 47 pounds and gained a decent amount of muscle in
  only 38 weeks? At the same time, many of us lack the motivation to walk around
  the block once a day. Is it a matter of genetics? Are we just the way we are
  and can't change anything about it?
    </p>
    <p>
  The truth is very uncomfortable and makes many excuses and apologies
  meaningless. What you are about to hear may take you out of your comfort zone.
  The reason why some of us just make it and others don't is not 'I'm just like
  that and there's nothing I can do about it'. The thing that makes us different
  is what we do, it's our choices. It is not a question of disposition! So the
  only decisive thing is our motivation, the inner drive, i.e. good and solid
  reasons to do something. You have a goal? Why do you want to achieve it? What
  drives you? What are your good reasons? Write them down!
    </p>
    <p>
  Philipp had very good reasons. His motivation was solid and consistent. He had
  been struggling with being overweight for several years. Although he has been
  working out regularly, there were still bad habits in his daily routine that
  kept him from reaching his desired weight. Philipp also wanted to feel more
  comfortable and to take care of his health in the long run. Vanity, ambition
  and striving for ideals also played a huge role. This is Philipp's solid
  mental foundation, which ultimately moved him to take decisive action.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} imageDesc={props.data.mdx.frontmatter.image_second_desc} imageAuthor={props.data.mdx.frontmatter.image_second_credit_text} imageLink={props.data.mdx.frontmatter.image_second_credit_link} mdxType="Image" />
    <h3>{`The First Contact`}</h3>
    <p>
  I posted photos from a bodybuilding competition on a social media platform
  shortly before our first contact. These photos showed my shape before and
  after the diet. Philipp had seen the photos and they obviously impressed him.
  Shortly after that, he contacted me to learn everything about my diet and
  workout routine.
    </p>
    <p>
  Philipp and I had a longer conversation especially about nutrition and workout
  strategies. Many questions could be answered in this first conversation and in
  particular Philipp quickly realized that his goal is different from mine and
  therefore some aspects would have to be adjusted. Philipp and I are good
  friends, so I made him the offer to be his diet, workout and motivation coach.
    </p>
    <p>
  In the weeks and months to come, I noticed that Philipp is extremely serious
  about his commitments. So he took a few days to think about the collaboration.
  Fortunately, he agreed which makes me pretty grateful in hindsight especially
  whenever I look at his transformation. The next section describes Philipp's
  diet in detail.
    </p>
    <h2>{`You must optimize your diet to lose weight!`}</h2>
    <p>
  A rule of thumb says that a successful diet consists of <b>70 % nutrition</b>{" "}
  and <b>30 % proper workouts</b>. Therefore this section is dedicated to the
  most important component to losing weight quickly and effectively which is the
  diet. The first meal plan for weight loss I created for Philipp, which can be
  seen below, provides <b>2290 calories</b>, <b>260 g of protein</b>,{" "}
  <b>220 g of carbohydrates</b> and <b>40 g of fat</b>. This yields a
  macronutrient ratio of <b>45 % protein</b>, <b>39 % carbohydrates</b>, and{" "}
  <b>16 % fat</b>. Use this free{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">app</NavLinkGlobal> to effortlessly determine
  both macronutrient percentages and total macronutrient amounts. But how did I
  calculate Philipp's daily calorie needs? That's actually not a secret, there
  is a pretty straightforward equation I use for my athletes. A very accessible
  and detailed explanation can be found{" "}
  <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    HERE
  </NavLinkGlobal>
  .
    </p>
    <p>
  The following section describes the diet meal by meal. This way you'll
  understand the purpose of each chosen food.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Meal`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Food`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Amount`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Breakfast`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rice cakes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75 g (2.6 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cottage cheese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 g (7 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chicken breast`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g (3.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whole grain bread`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g (1.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cheese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35 g (1.2 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g (1.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lunch`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g (3.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chicken breast`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300 g (10.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Green beans`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 g (5.3 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Dinner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cottage cheese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500 g (17.6 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walnut oil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 g (0.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g (1.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banana`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g (4.4 oz)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Breakfast`}</h3>
    <p>
  This diet plan's breakfast is rich in protein and carbohydrates and at the
  same time extremely low in fat. Especially in the morning it is important to
  provide your body with easily digestible protein and quickly available
  carbohydrates. Cottage cheese and chicken breast are the sources of protein.
  Also, this breakfast does not require any preparation time. It is therefore
  very suitable when you have little time available.
    </p>
    <h3>{`First Snack`}</h3>
    <p>
  To prevent cravings from arising in the first place, it is important to eat
  meals on a regular basis. In Philipp's case, there are 3 larger and 2 smaller
  meals. The first snack is mainly a supplier of protein and carbohydrates. In
  addition to protein, cheese also provides a moderate amount of fat. Also keep
  in mind that quick meal praparation has been of great importance to Philipp.
  For instance a slice of cheese just needs to be placed on a slice of bread,
  done. Moreover a whey protein shake can be taken anywhere and anytime if put
  in a shaker bottle. If you want to learn more about how to best prepare your
  everyday whey protein shake, go ahead and read{" "}
  <NavLinkGlobal to="/blog/what-is-whey-protein-exactly" mdxType="NavLinkGlobal">
    THIS ARTICLE
  </NavLinkGlobal>
  .
    </p>
    <h3>{`Lunch`}</h3>
    <p>
  Lunch is probably the largest meal in terms of food volume. It is also
  particularly rich in proteins and carbohydrates. In addition, it provides
  solid amounts of fiber, which are mainly present in green beans. Of course,
  you can also swap rice for buckwheat, which has higher nutritional value and
  also provides better satiety. Likewise, potatoes or even sweet potatoes would
  be a useful alternative. You could also alternate the carbohydrate sources
  regularly. I would not resort to pasta. They have an extremely high glycemic
  index, which means they cause high insulin levels, which can quickly lead to
  cravings. Experience has also shown that they do not keep you full for long,
  which is anything but helpful, especially in a diet.
    </p>
    <h3>{`Dinner`}</h3>
    <p>
  The macronutrient composition of dinner is completely different compared with
  other meals. Dinner is practically low carb and consists mainly of protein and
  fats. The special thing about the protein in cottage cheese is that it is
  predominantly{" "}
  <NavLinkGlobal to="/blog/what-is-micellar-casein" mdxType="NavLinkGlobal">casein</NavLinkGlobal>.
  Casein is a very slow-digesting protein, which makes it a great source of
  protein for the nighttime. This way your body is supplied with amino acids
  over a long period of time. If you don't like the taste of low-fat cottage
  cheese, or if you need a healthy snack every now and then, you can
  alternatively fall back on{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_casein_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_casein_text}
  </AffiliateLink>{" "}
  protein powder as well.
    </p>
    <p>
  Walnut oil is a very healthy source of fat. It provides omega-3
  polyunsaturated fatty acids, which are very important for your health and also
  support your dietary efforts. There is still a common misconception that
  dietary fat makes you fat. First and foremost, dietary fat has nothing to do
  with body fat. The latter is produced by the body when there is a{" "}
  <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    calorie surplus
  </NavLinkGlobal>{" "}
  with regard to food. Where these calories come from does not matter in the
  first place! <b>So remember</b>: you need healthy fats in your diet regardless
  of whether you are aiming to lose weight or not! A good alternative to walnut
  oil is flax oil. It also has an excellent profile of fatty acids while having
  a different flavor than walnut oil.
    </p>
    <h3>{`Second Snack`}</h3>
    <p>
  The second snack has two functions. It is taken either as a meal between lunch
  and dinner or directly after training. Accordingly, it depends on whether you
  are training on that particular day or not. Again, it is a meal that requires
  virtually no preparation time. The macronutrients supplied here are primarily
  quickly absorbable{" "}
  <NavLinkGlobal to="/blog/what-is-whey-protein-exactly" mdxType="NavLinkGlobal">
    whey protein
  </NavLinkGlobal>{" "}
  and quickly available carbohydrates. A viable alternative for the banana in
  this meal would be{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>{" "}
  which is very soluble oatmeal that you can simply add to the same shaker or
  blender as whey protein. Instant oatmeal provides you with complex
  carbohydrates that keep you full for a long time because of its low glycemic
  index. To get the same amount of calories, simply replace one banana (125 g)
  with one serving of instant oatmeal (30 g).
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} imageDesc={props.data.mdx.frontmatter.image_third_desc} imageAuthor={props.data.mdx.frontmatter.image_third_credit_text} imageLink={props.data.mdx.frontmatter.image_third_credit_link} mdxType="Image" />
    <h2>{`Use cheatdays to overcome stagnation!`}</h2>
    <p>
  Let's talk about a very popular and at the same time very controversial topic,
  which is the <b>cheat day</b>. This is also known as <b>carb backloading</b>{" "}
  or <b>refeed</b>. There are self-proclaimed fitness gurus who come up with the
  wildest and most creative guidelines. Some of them specify a fixed
  macronutrient distribution and only a slightly increased amount of calories.
  Others advocate a very simple approach that can be summed up as "eat anything
  you want and as much as you want as long as you have the discipline to stick
  to your diet meal plan the next day."
    </p>
    <p>
  I definitely see myself more as a proponent of the latter approach. Philipp{" "}
  <b>
    ate and drank literally everything he wanted every two weeks on a weekday of
    his choice
  </b>
  , usually a Saturday or even a holiday, without restricting himself or even weighing
  his food. <b>
    Fast food such as burgers with fries, pizza, ice cream, chips, coke, sweets
    or other treats
  </b> is literally no problem on this one day. Whatever your cravings are, go for
  it and eat whatever you want. Does it work? Just take a look at Philipp's before
  and after photos to get the answer!
    </p>
    <p>
  <b>But why does this approach work?</b> The longer you stick to your diet, the
  bigger the risk that your metabolism will slow down. Speaking in terms of
  genetics, your body basically goes into survival mode due to low calorie
  intake. Your metabolism runs as economically as possible. By having a cheat
  day every two weeks, you oversaturate your organism with calories and
  carbohydrates, so your body speeds up its metabolic rate again. After all, it
  now has the impression that a sufficient supply of calories is guaranteed.
    </p>
    <p>
  <b>Another reason for the effectiveness of this approach is psychological</b>.
  What are you more likely to get through mentally, knowing that you can eat
  your favorite food again for the first time in a year, or the certainty of
  eating every two weeks on a whim? Your diet is thus divided into short sprints
  and at the end of each small sprint there is a big reward. This way you
  condition yourself for success, you reward yourself for your ambition and
  discipline.
    </p>
    <h2>{`Optimize your diet with this nutrition strategy!`}</h2>
    <p>
  Have you wondered why you should eat the same on workout days as well as on
  non-workout days? Most importantly, you may have wondered why you should eat
  the same amount of calories and macronutrients regardless of whether working
  out or not. In fact, this is a very good point! And in Philipp's case, we've
  taken advantage of this knowledge to lose fat and build muscle at the same
  time. You will now learn how we proceeded.
    </p>
    <p>
  As the diet progressed, we gradually had to reduce Philipp's calories a bit
  more in order to stay on track for success. Nevertheless, it has been
  important for the long-term success that Philipp does not starve at any time.
  That's why I created two different nutrition plans, one for workout days and
  the other for non-workout days. When working out, the calories amount to{" "}
  <b>2015 kcal</b> and are thus almost unchanged from the beginning of the diet.
  On days without workouts the calories were reduced to <b>1535 kcal</b>. Which
  makes total sense, since the calories burned decrease. The difference in
  calories is mainly characterized by a reduction in carbohydrates on
  non-workout days, as these are especially needed for intensive exercises.
    </p>
    <h3>{`Diet Plan On Workout Days`}</h3>
    <p>
  Fast and effective weight loss without starving is possible. It is also much
  easier than you might have imagined. The secret is, among other things, to eat
  foods that are as unprocessed as possible. They keep you full for a long time
  and provide you with important nutrients to keep your metabolic rate as high
  as possible. Philipp's meal plan for workout days at the end of his diet is
  described in the following section.
    </p>
    <p>
  There are certain similarities between this and the first diet plan. The meal
  plans were not completely changed, which offers both a food variety and
  established eating habits. Short meal explanations are provided below.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Meal`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Food`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Amount`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Breakfast`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 g (1.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">{`Instant oatmeal`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 g (1.1 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Walnut oil`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`7 g (0.2 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lunch`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Broccoli`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g (3.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chicken breast`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`350 g (12.3 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rice`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110 g (3.9 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Dinner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cottage cheese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500 g (17.6 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eggs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 pcs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 g (1.8 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banana`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g (4.4 oz)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Breakfast`}</h4>
    <p>
  In order to bring your body out of a catabolic metabolic state after a night's
  rest, it is important to eat quickly digestible proteins in combination with
  complex carbohydrates. For this purpose, a portion of{" "}
  <NavLinkGlobal to="/blog/what-is-whey-protein-exactly" mdxType="NavLinkGlobal">
    whey protein
  </NavLinkGlobal>{" "}
  with{" "}
  <AffiliateLink url={props.pageContext.frontmatter.product_instant_oats_url} mdxType="AffiliateLink">
    {props.pageContext.frontmatter.product_instant_oats_text}
  </AffiliateLink>{" "}
  is optimal. Round off your breakfast with a tablespoon of walnut oil or
  linseed oil. These ingredients can easily be added to a shaker or blender. The
  recommended liquid is low-fat (lactose-free) milk, oat milk, coconut milk or
  simply water.
    </p>
    <h4>{`Lunch`}</h4>
    <p>
  With a generous serving of rice, lunch will provide you with the greatest
  amount of carbohydrates. As a source of fiber, it's best to add broccoli to
  the portion of chicken breast. Broccoli is not only a source of fiber, but it
  also helps you being full and it has extremely important health properties as
  an alkaline food. Alkaline foods raise the pH of your blood, so they make it
  less 'acidic'. This can have a positive effect on your joints and your
  regeneration!
    </p>
    <h4>{`Dinner`}</h4>
    <p>
  Dinner is kept very simple. It consists of a package of low-fat cottage cheese
  and 3 whole eggs. The goal is to keep your blood sugar level as low as
  possible through this combination of proteins and fats before going to bed.
  This way you will lose fat while sleeping and build muscle by releasing growth
  hormones like HGH and IGF-1. You can add flavor and enhance nutritional value
  by adding fresh or frozen fruit. The preparation of the eggs is entirely up to
  you, for example, you can fry them with fresh chives and herbs and serve them
  with a tomato and cucumber.
    </p>
    <h4>{`Snack`}</h4>
    <p>
  The snack is especially intended as a meal after workouts and requires
  practically no preparation time. In case you are on the go, you can simply put
  the whey protein in a shaker and pack it together with the banana. This way
  you have a full-fledged small meal with you, which supplies your body with
  quickly available proteins and short-chain carbohydrates. Especially directly
  after training, this leads to a strategically important release of insulin,
  which ensures that amino acids can be metabolized quickly and effectively and
  migrate into your muscles.
    </p>
    <h3>{`Meal Plan - Day Off`}</h3>
    <p>
  The following meal plan shows what Philipp has been eating on non-workout
  days. The calories here are reduced from <b>2015 kcal</b> to <b>1535 kcal</b>.
  As already mentioned above, this results mainly from a reduced amount of
  carbohydrates. In particular, the protein intake is kept relatively high to
  supply the muscles with amino acids, which counteracts muscle loss. This is
  extremely important for your dieting efforts, as it will become increasingly
  difficult for you to lose weight the less muscle you have. For more
  information on this topic, see this article on{" "}
  <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    calculating your calorie needs
  </NavLinkGlobal>
  .
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Meal`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Food`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Amount`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Breakfast`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Banana`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`125 g (4.4 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cottage cheese`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 g (7 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rice cakes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 pcs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lunch`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Onions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g (3.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chicken breast`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400 g (14.1 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Zucchini`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150 g (5.3 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Dinner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mushrooms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 g (3.5 oz)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eggs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5 pcs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Snack`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><AffiliateLink url={props.pageContext.frontmatter.product_whey_url} mdxType="AffiliateLink">{`Whey protein`}</AffiliateLink></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 g (1.8 oz)`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Breakfast`}</h4>
    <p>
  Breakfast consists of banana and rice cakes as sources of carbohydrates, which
  is rounded off with a pack of cottage cheese as protein provider. The
  carbohydrates are composed of both simple and complex carbohydrates. Cottage
  cheese mainly consist of{" "}
  <NavLinkGlobal to="/blog/what-is-micellar-casein" mdxType="NavLinkGlobal">
    casein protein
  </NavLinkGlobal>{" "}
  which supplies amino acids for several hours.
    </p>
    <h4>{`Lunch`}</h4>
    <p>
  Unlike lunch on workout days, a day off lunch does not contain direct sources
  of carbohydrates. The onions and zucchini are a source of fiber while
  contributing a pleasant flavor. They also ensure that the portion of chicken
  breast can be prepared in a tastier way. Protein foods, such as a big portion
  of chicken breast, have the great advantage of keeping you full for a long
  time and equally supplying you with important amino acids. This keeps your
  blood sugar level constantly low and you lose weight and build muscle quickly
  and effectively!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} imageDesc={props.data.mdx.frontmatter.image_fourth_desc} imageAuthor={props.data.mdx.frontmatter.image_fourth_credit_text} imageLink={props.data.mdx.frontmatter.image_fourth_credit_link} mdxType="Image" />
    <h4>{`Dinner`}</h4>
    <p>
  Like lunch, dinner is a low-carb meal. The strategy behind this is that
  Philipp only ate carbohydrates in the morning so that he can subsequently
  metabolize body fat better during the rest of the day. Eggs are a source of
  both protein and healthy saturated fat. In addition, eggs contain healthy HDL
  cholesterol and are a source of lecithin, which can increase mental
  performance. Mushrooms are micronutrient suppliers and increse food volume.
  They were added to the eggs to make a delicious omelet.
    </p>
    <h4>{`Snack`}</h4>
    <p>
  The snack consists of a generous{" "}
  <NavLinkGlobal to="/blog/what-is-whey-protein-exactly" mdxType="NavLinkGlobal">
    whey protein
  </NavLinkGlobal>{" "}
  shake which should be consumed when you are a little hungry. Especially with a
  delicious flavor it can significantly improve your mood in a diet.
    </p>
    <h2>{`These activities help with weight loss...`}</h2>
    <p>
  Just like Philipp, you may also have the desire to lose weight while gaining
  muscle. You already know the tiniest detail about Philipp's diet meal plan.
  But what about working out? After all, there are countless types of sports and
  it's pretty difficult to tell which are effective and which are not. Now
  you'll find out what you really need to keep in mind when exercising and which
  sports combination is by far the best.
    </p>
    <h3>{`To lose fat you need to build muscle!`}</h3>
    <p>
  In order to lose weight you need to burn more calories than you eat. So you
  might be tempted to do any sport in order to simply burn calories to achieve a
  calorie deficit, such as jogging, soccer, cardio, cycling or aerobics classes.
  Sure, you burn a lot of calories, but will it really make you slim in the long
  run? The answer is definitely <b>NO</b>! This may sound like a shock to you,
  but the explanation is pretty simple. Your body will do everything it can to
  protect its energy reserves, that is, its fat stores. This is the way we are
  basically programmed by evolution.
    </p>
    <p>
  As soon as your body goes into 'survival mode' due to a calorie deficit, it
  will metabolize your muscles to lower its{" "}
  <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    basal metabolic rate
  </NavLinkGlobal>
  . As a result, it becomes increasingly difficult for you to continue losing weight
  until your efforts become totally ineffective. But what can we do about it? A protein-rich
  diet is fundamentally important in order to protect your muscles with the amino
  acids supplied. Moreover, you have to make sure that your muscles are sufficiently
  stimulated to prevent muscle atrophy. In addition, it would be desirable to gain
  muscle which in turn increases your <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    basal metabolic rate
  </NavLinkGlobal>. As a result your diet becomes much easier and you will be able
  to eat more without gaining weight in the long run.
    </p>
    <h3>{`Philipp's Workout Plan`}</h3>
    <p>
  The following workout plan consists of 3 units which are labeled <b>legs</b>,{" "}
  <b>push</b> and <b>pull</b>, each performed in a saparate workout. Philipp
  executed this workout plan 3 to 4 times per week. The units are intended to be
  periodic, so after doing pull you basically start over with legs. Between each
  set take a break of about 90 seconds.
    </p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Muscle groups`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Exercise`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sets x Reps`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Legs`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Squats`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 8-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Press`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Extension`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leg Curl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 x 8-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Calf Raises`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 x 16-20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Push`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Benchpress`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Butterfly`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dumbbell Shoulder Press`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dumbbell Lateral Raises`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tricep Pushdown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EZ Bar Tricep Extension`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pull`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pull-Ups`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dumbbell Rows`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 12-14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seated Cable Rows`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 16-20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dumbbell Bicep Curls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3 x 6-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Preacher Curls`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2 x 12-14`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Leg Workout`}</h4>
    <p>
  The entire lower body workout consists of 5 exercises that stimulate your
  front and back thighs as well as your calves. After you have warmed up
  extensively, the first exercise you perform is squats. This is a basic
  exercise that strengthens not only your thighs but also your entire body.
  Follow up with leg presses and leg extensions, each of which stimulate
  primarily your quadriceps. The thigh workout concludes after leg curls which
  stimulate the leg flexor in isolation. You're done with your workout after
  several sets of calf raises.
    </p>
    <h4>{`Push`}</h4>
    <p>
  The term <b>push</b> is used to describe exercises in which a weight is moved{" "}
  <b>away</b> from your body. The corresponding muscles perform a{" "}
  <b>stretching</b> movement. These muscle groups include your <b>chest</b>,{" "}
  <b>shoulders</b> and the arm extensor also know as <b>triceps</b>. The session
  begins with chest exercises which are incline bench press and butterfly.
  Butterfly can be performed using a machine, cables or with dumbbells. The
  chest exercises are followed by shoulder exercises which consist of vertical
  shoulder press and lateral raises. The training session concludes with tricep
  pushdown and EZ bar tricep extension.
    </p>
    <h4>{`Pull`}</h4>
    <p>
  The <b>pull unit</b> is essentially the opposite of the push unit which
  stimulates muscle groups that perform a{" "}
  <b>flexing movement towards the body</b>, such as your entire back and your
  biceps. The workout starts with 3 sets of pull-ups, which you can also
  substitute with lat pulldowns, especially if you're not yet strong enough to
  perform regular pull-ups. This is followed by dumbbell rows and seated cable
  rows. This variety of exercises will reach the complex back muscles from all
  angles. The unit concludes with 5 sets of bicep exercises such as dumbbell
  bicep curls and preacher curls.
    </p>
    <h3>{`Cardio should be short and intense!`}</h3>
    <p>
  Although you should focus your workout on lifting weights to gain muscle, it
  is also important to engage in a moderate amount of aerobic activities that
  specifically burn calories, such as<b>cardio</b>. In fact, Philipp's cardio
  routine was pretty simple. It was important to him to do cardio as
  time-effective and low-volume as possible. Philipp performed an intense
  15-minute cardio workout on the stepper or cross trainer after every other
  regular workout. If that's too boring for you, do something you enjoy as for
  instance aerobics, biking, rowing, playing soccer, football or volleyball. You
  get the idea!
    </p>
    <h3>{`Ab workouts are overrated!`}</h3>
    <p>
  You probably noticed that Philipp's workout plan does not contain any explicit{" "}
  <b>abdominal exercises</b>. That's because you can actually perform ab
  exercises right after every workout. Philipp's abdominal workout consists of{" "}
  <b>3 sets of leg raises, 20 to 25 reps each</b>. Leg raises is an excellent ab
  exercise because they basically strengthen the entire abdominal muscle and in
  particular your V-cut abs.
    </p>
    <p>
  <b>But is there a special workout that helps lose belly fat?</b> The fat
  distribution of our body is both genetically determined and gender-dependent.
  Some tend to gain belly fat while others gain fat on their hips, legs or butt.
  This means that you cannot specifically lose fat on a certain part of your
  body, as some dubious TV commercials may promise in order to selling their
  products. <b>Consider the following a law of nature:</b> in order to lose
  weight you need a{" "}
  <NavLinkGlobal to="/blog/how-do-i-calculate-my-calorie-needs" mdxType="NavLinkGlobal">
    calorie deficit
  </NavLinkGlobal>
  . You cannot influence where you lose weight fastest or most likely.
    </p>
    <p>
  <b>Does this mean that an abdominal workout is unnecessary?</b> No, absolutely
  not! Strong abdominal muscles are of enormous importance for your health. They
  ensure a healthy posture and support your spine. Pronounced abdominal muscles
  are also visible with a higher body fat percentage. This in turn means that
  you will have a visible six pack at a much earlier point in your diet. These
  are many reasons why a regular abdominal workout should be an integral part of
  your workout routine.
    </p>
    <h2>{`FAQ - Frequently Asked Questions`}</h2>
    <p>
  Do you know the feeling when you've been reading up on a topic for what feels
  like an eternity, but then you realize with disappointment that exactly{" "}
  <b>YOUR</b> particular question remains unanswered? I know this only too well,
  that's why you get here a question-answer catalog with which even your most
  specific question will be answered. Let's go!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_faq)} imageAlt={props.data.mdx.frontmatter.image_faq_alt} imageDesc={props.data.mdx.frontmatter.image_faq_desc} imageAuthor={props.data.mdx.frontmatter.image_faq_credit_text} imageLink={props.data.mdx.frontmatter.image_faq_credit_link} mdxType="Image" />
    <h3>{`What should I eat for dinner at night to lose weight?`}</h3>
    <p>
  In the evening, you should avoid carbohydrates in favor of protein and fat.
  Foods containing high amounts of protein include cheese, cottage cheese, eggs,
  egg whites, meat, fish, lentils, beans, (chick)peas and protein powders. In
  order to eat high quality fat sources, make sure they contain polyunsaturated
  fatty acids. Good sources are walnuts, almonds, Brazil nuts, linseed oil,
  walnut oil, olives and olive oil, fish or alternatively codliver oil.
    </p>
    <p>
  Simply use the meal plans for weight loss described in this article or just
  let your creativity run wild. How about grilled chicken breast and a delicious
  salad with walnuts and an olive oil based dressing. Or simply cottage cheese
  with fresh or frozen fruit and a tablespoon of walnut or linseed oil. You'll
  quickly find out what works best for you.
    </p>
    <h3>{`What is the #1 most effective way to lose weight?`}</h3>
    <p>
  This is a question that is asked often and, at first glance, easy to answer.
  Unfortunately, most people have a hard time finding solid and objective
  information to answer this question. If you have read the article in full, you
  will already have a good impression of how you can build muscle while losing
  weight. You will also find more articles on this topic in this blog in the
  future.
    </p>
    <p>
  The best way to lose belly fat effectively is to have a calorie deficit which
  is slightly below your maintenance calories. This will ensure that your
  metabolism won't slow down in order to save calories instead of metabolizing
  body fat. The amount of protein in your diet should be relatively high in
  conjunction with moderate amounts of healthy fats. A low-carb diet is usually
  the most optimal strategy. Your workout should consist of both muscle-building
  exercises and a decent amount of cardio.
    </p>
    <h3>{`How long will it take to lose 20 pounds?`}</h3>
    <p>
  A calorie deficit of <b>300 calories per day</b> results in a weight loss of{" "}
  <b>2.6 pounds of pure body fat per month</b>. Accordingly you will lose{" "}
  <b>20 pounds of pure body fat in less than 9 months</b>. However, as you can
  conclude from Philipp's success story, it is also possible to lose more than
  twice as much weight in the same time. Anyway, I strongly recommend you to be
  patient and you shouldn't be too concerned about your weight in the sense of a
  value on your scale. Your goal is visual, you want to see your dream body in
  the mirror, what your scale says is secondary. You can find all the
  information you need in this article.
    </p>
    <h3>{`How long after starting weight training will I lose weight?`}</h3>
    <p>
  The best way to lose weight is a calorie deficit which is actually the only
  way to sustainably lose weight. In other words, you need to burn more calories
  than your food provides. Does this mean that lifting weights at the gym is
  ineffective? Not at all! Lifting weights burns calories and at the same time
  is the cause for muscle gain. Building muscle increases your basal metabolic
  rate which means that you burn more calories at rest than before. This makes
  it easier for you to achieve a calorie deficit to reach your diet goals
  faster.
    </p>
    <h3>{`Can I lose fat and gain muscle at the same time?`}</h3>
    <p>
  Philipp's transformation shows impressively that gaining muscle while losing
  weight is possible if you follow the advices in this article. Working out at
  the gym helps you build muscle which in turn increases your basal metabolic
  rate. This circumstance helps you lose weight tremendously. Additionaly,
  lifting weights helps you keep your metabolic rate at a consistently high
  level. So consider working out as a fundamental activity, which is
  indispensable for losing weight.
    </p>
    <h2>{`What Did You Learn From This Article?`}</h2>
    <p>
  Thanks for joining Philipp on his muscle building and weight loss journey. Now
  you know everything about his diet and workout. Philipp's transformation
  photos prove his strategy right and clearly demonstrate its effectiveness.
  There are no secrets here and no information hiding behind a price tag. You
  can easily adopt the plans so that you too can achieve your goals! What are
  you waiting for?
    </p>
    <ul>
      <li parentName="ul">{`You need a diet meal plan in order to achieve your fitness goals.`}</li>
      <li parentName="ul">{`It's best to use a reliable `}<NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">{`calorie counter`}</NavLinkGlobal>{` to track your diet.`}</li>
      <li parentName="ul">{`You can divide your diet plan into workout and non-workout days.`}</li>
      <li parentName="ul">{`You should have a cheat day every two weeks.`}</li>
      <li parentName="ul">{`Gaining muscle by going to the gym increases the effectiveness of your diet.`}</li>
      <li parentName="ul">{`Cardio can help you lose weight.`}</li>
      <li parentName="ul">{`Keep a `}<NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">{`weight log`}</NavLinkGlobal>{` to measure your success.`}</li>
    </ul>
    <Disclosure mdxType="Disclosure" />
    <h2>{`Lose Weight and Build Muscle`}</h2>
    <p>
  You read articles like this one because you have real goals. You want to
  achieve something, something that is important to you. You want to lose some
  weight to feel more comfortable, to be healthier, or to please yourself more.
  You want to build up muscles in order to have a slim and beautiful physique in
  the future. Supplements can be very helpful here, but at the end of the day,
  your <b>total daily calories</b> and the <b>macronutrient distribution</b>{" "}
  make the difference between <b>success and failure</b>!
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_app_promotion)} imageAlt={props.data.mdx.frontmatter.image_app_promotion_alt} imageDesc={props.data.mdx.frontmatter.image_app_promotion_desc} imageAuthor={props.data.mdx.frontmatter.image_app_promotion_credit_text} imageLink={props.data.mdx.frontmatter.image_app_promotion_credit_link} mdxType="Image" />
    <h3>{`Total Calories and Macronutrient Distribution`}</h3>
    <p>
  To stay on track, you need to control your calories and keep an eye on them.
  But also the macronutrient distribution, i.e. the ratio of eaten protein,
  carbohydrates and fats, is extremely important. Determining these values daily
  by hand in a spreadsheet is very time-consuming and frustrating. To help you
  out, I'm providing you with a 100% free{" "}
  <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">calorie counter app</NavLinkGlobal>. It also
  helps you to log your weight and analyze it graphically. What are you waiting
  for? <NavLinkGlobal to="/#appstores" mdxType="NavLinkGlobal">Start NOW!</NavLinkGlobal>
    </p>
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      